import logo from './logo.svg';
import './App.css';
import { Auth } from './components/auth';
import { Toaster } from "./components/ui/toaster"
import { Outlet } from "react-router";
import { AuthProvider } from './context/AuthContext';

function App() {
  return (
      <AuthProvider>
        <Outlet />
        <Toaster />
      </AuthProvider>
  );
}

export default App;
